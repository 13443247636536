import './Candidate.css'
import TableHead from "../../../components/utilities/table/TableHead";
import TableRow from "../../../components/utilities/table/TableRow";
import {useContext, useEffect, useState} from "react";
import Status from "../../../components/quiz-result/Status";
import Table from "../../../components/utilities/table/Table";
import TransparentPageLayout from "../../../layout/page-layout/TransparentPageLayout";
import FlexBox from "../../../components/utilities/FlexBox";
import {Button} from "react-bootstrap";
import TableBody from "../../../components/utilities/table/TableBody";
import ProfileImage from "../../../components/profile/ProfileImage";
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {resendTest} from '../candidate-utils/CandidateUtils'
import {resendResults} from '../candidate-utils/CandidateUtils'

import {
    getCandidateFullName
} from "../../../utilities/Utilities";
import {
    API_URL_PREFIX,
    APP_URL_PREFIX, PASS_THRESHOLD, TEST_REVIEW_PRIVILEGE,
} from "../../../utilities/Constants";
import Loader from "../../../components/utilities/Loader";
import {CameraFill, Images, Pencil, X} from "react-bootstrap-icons";
import ConfirmModal from "../../../components/utilities/ConfirmModal";
import RoundedLabel from "../../../components/utilities/RoundedLabel";
import {
    CustomErrorToast,
    CustomLoadingToast, CustomToastContainer, updateCustomErrorToast,
    updateCustomSuccessToast
} from "../../../components/utilities/CustomToast";
import SendTest from "../../../components/send-test/SendTest";
import CompanyTeamContext from "../../../CompanyTeamContext";
import Tooltip from "../../../components/utilities/Tooltip";
import {IframeModal, TestChartsButton, TestReviewButton} from "../../quiz/quiz/QuizzesList";
import {getTestName, isLiveTestOrQuiz} from "../../test/tests-list/TestsList";

const Candidate = () => {
    const [candidate, setCandidate] = useState({})
    const [loader, setLoader] = useState(true)

    const [quizzes, setQuizzes] = useState([])
    const {id} = useParams()
    const company = useContext(CompanyTeamContext)

    useEffect(() => {
        axios.get( API_URL_PREFIX+"/GetCandidate.html?id=" + id)
            .then(res => res.data)
            .then(data => {
                if (data) {
                    setCandidate(data)
                    setLoader(false)
                    if(company){
                        document.title  = data.firstName + " - " + company.basicTitle
                    }
                }
            })
            .catch(e => setLoader(false) && console.log(e))
        return () => {
            if(company){
                document.title = company.basicTitle
            }
        }
    }, [id, company])

    useEffect(()=>{
        refresh()
    },[candidate])

    const refresh = ()=>{
        if(candidate && candidate.key){
            axios.get(API_URL_PREFIX+"/GetCandidateQuizzes.html?id="+candidate.key)
                .then(e=>{
                    let data = e.data;
                    if(!data || data.err){
                        CustomErrorToast("Error fetching quizzes: "+data.err.info)
                    }
                    else{
                        setQuizzes(data.sort((a,b)=> {
                            return b.date - a.date;
                        }))
                    }
                })
                .catch(err=>{
                    console.log(err)
                })
        }
    }
    if(loader){
        return (<TransparentPageLayout>
            <Loader loader={loader}/>
        </TransparentPageLayout>)
    }

    return (<TransparentPageLayout>
        <Loader loader={loader}/>
        <CustomToastContainer />
        <Header candidate={candidate} quizzes={quizzes}/>
        <FlexBox justify={"top"} direction={"column"} style={{width: "100%"}}>
            <Quizzes  setQuizzes={setQuizzes} candidate={candidate} quizzes={quizzes} refresh={refresh}/>
        </FlexBox>
        <br/>
        <FlexBox justify={"top"} direction={"column"} style={{width: "100%"}}>
            <Notes candidate={candidate}/>
        </FlexBox>
    </TransparentPageLayout>)
}

const Header = ({candidate, quizzes}) => {
    const [showDelete, setShowDelete] = useState(false)
    const navigate = useNavigate();

    return (<div>
        <ConfirmModal title={"Are you sure you want to delete " + getCandidateFullName(candidate) + "?"}
                      body={"this operation is permanent and can not be undone"}
                      callback={() => {
                      }}
                      yes={"Delete"}
                      no={"Cancel"}
                      variant={"danger"}
                      setShow={setShowDelete}
                      show={showDelete}
        />
        <FlexBox className={"container"} justify={"space-between"} style={{padding: "0px 0px", width: "80vw"}}>
            <FlexBox justify={"start"} style={{width: "70%"}}>
                <div>
                    <Link style={{
                        marginBottom: "8px",
                        color: "#4A8CD3",
                        fontWeight: "700",
                        fontSize: "20px",
                        textDecoration: "none"
                    }} to={APP_URL_PREFIX+"/candidate/list"}>Candidates List</Link>
                </div>
                <div style={{
                    color: "#979DA5", fontWeight: "700", fontSize: "20px"
                }}>&nbsp;/&nbsp;{getCandidateFullName(candidate)}</div>
            </FlexBox>
            <FlexBox style={{width: "30%"}} justify={"end"}>
                <Button onClick={() => (navigate(APP_URL_PREFIX+"/candidate/" + candidate.key + "/edit"))}
                        variant={"outline-success"}><Pencil/> Edit</Button>&nbsp;
                <Button onClick={() => setShowDelete(true)} variant={"outline-danger"}><X/> Delete</Button>
            </FlexBox>
        </FlexBox>
        <FlexBox style={{padding: "15px 0px"}} className={"container"} align={"center"} justify={"space-between"}>
            <CandidateDetails candidate={candidate}/>
            <QuizStats candidate={candidate} quizzes={quizzes}/>
            <CandidateStats candidate={candidate} quizzes={quizzes}/>
        </FlexBox>
    </div>)
}

const CandidateDetails = ({candidate}) => {
    return (<FlexBox justify={"center"} align={"center"} className={"candidate-details"}
                     style={{width: "30%", height: "110px", background: "#4760F1", padding: "30px"}}>
        <FlexBox justify={"space-between"} style={{width: "100%"}}>
            <ProfileImage alt={""} src={""}/>
            <FlexBox align={"start"} direction={"column"} style={{width: "80%"}}>
                <div id={"fullName"} style={{
                    lineHeight: "20px", color: "#fff", fontWeight: "800", fontSize: "20px"
                }}>{getCandidateFullName(candidate)}</div>
                <div style={{color: "#fff", fontWeight: "300", fontSize: "16px"}}>{candidate.email}</div>
                <div style={{color: "#fff", fontWeight: "300", fontSize: "16px"}}>{candidate.mobile}</div>
            </FlexBox>
        </FlexBox>
    </FlexBox>);
}

function QuizStats({candidate, quizzes}) {
    return (<FlexBox align={"center"} className={"candidate-details"}
                     style={{width: "30%", height: "110px", background: "#fff", padding: "30px"}}>
        <FlexBox justify={"space-between"} style={{width: "100%"}}>
            <RoundedLabel value={quizzes.length} />

            <FlexBox align={"start"} direction={"column"} style={{width: "80%"}}>
                <div style={{
                    lineHeight: "20px", color: "#162B36", fontWeight: "800", fontSize: "20px", width: "80%"
                }}>Tests assigned
                </div>
                <div style={{color: "#162B36", fontWeight: "300", fontSize: "16px"}}>to {candidate.firstName}</div>
            </FlexBox>

        </FlexBox>
    </FlexBox>);
}

function CandidateStats({candidate, quizzes}) {
    return (<FlexBox align={"center"} className={"candidate-details"}
                     style={{width: "30%", height: "110px", background: "#fff", padding: "30px"}}>
        <FlexBox justify={"space-between"} style={{width: "100%"}}>
            <RoundedLabel value={quizzes.filter(e=>e.quizScore >= 0).length} />

            <FlexBox align={"start"} direction={"column"} style={{width: "80%"}}>
                <div style={{
                    lineHeight: "20px", color: "#162B36", fontWeight: "800", fontSize: "20px", width: "80%"
                }}>Tests Taken
                </div>
                <div style={{color: "#162B36", fontWeight: "300", fontSize: "16px"}}>by {candidate.firstName}</div>
            </FlexBox>

        </FlexBox>
    </FlexBox>);
}

const Notes = ({candidate}) => {
    const [saving, setSaving] = useState(false)
    const [notes, setNotes] = useState("")

    useEffect(() => {
        if (candidate) setNotes(candidate.notes)
    }, [candidate])

    const saveNote = () => {
        setSaving(true)
        axios.put( API_URL_PREFIX+"/SaveNote.html", {key:candidate.key,notes: notes})
            .then(res => res.data)
            .then(data => {
                setSaving(false)
            })
            .catch(e => setSaving(false) && console.log(e))
    }

    return (<div className={"container"}
                 style={{background: "#fff", boxShadow: "0px 1px 16px 0px #30384B1A", height: "80%", padding: "20px"}}>
        <FlexBox justify={"center"} align={"start"} direction={"column"}>
            <FlexBox justify={"space-between"} style={{width: "100%"}}>
                <h3 style={{color: "#364454", fontWeight: "500", fontSize: "20px"}}>Notes</h3>
                <Button onClick={saveNote} disabled={saving}
                        variant={"primary"}>{saving ? "Saving..." : "Save"}</Button>
            </FlexBox>
            <br/>
            <textarea value={notes} onChange={e => setNotes(e.target.value)} className={"input"} rows={4}/>
        </FlexBox>
        <br/>

    </div>)

}

const Quizzes = ({setQuizzes,candidate, quizzes, refresh}) => {
    const [sendTest, setSendTest] = useState(false)
    const [showIframe, setShowIframe] = useState(false)
    const [iframeSrc, setIframeSrc] = useState("")

    return (<><div className={"container"}
                 style={{background: "#fff", boxShadow: "0px 1px 16px 0px #30384B1A", height: "80%", padding: "20px"}}>
            {showIframe && <IframeModal setShow={setShowIframe} src={iframeSrc} />}
            <FlexBox justify={"space-between"}>
            <h3 style={{color: "#364454", fontWeight: "500", fontSize: "20px"}}>Pending Quizzes for <span
                style={{fontWeight: "bold"}}>{candidate.firstName}</span></h3>
            <Button id={"sendTestInvitation"} onClick={e=>setSendTest(true)} variant={"primary"}>Send Test Invitation</Button>
        </FlexBox>
        <br/>
        <QuizzesTable setQuizzes={setQuizzes} quizzes={quizzes.filter && quizzes.filter(e=>e.quizScore == null) || []} candidate={candidate} label={"assigned to"} />

        {sendTest && <SendTest candidate={candidate} setShow={setSendTest} refresh={refresh} />}
    </div><br/>
            <div className={"container"}
                 style={{background: "#fff", boxShadow: "0px 1px 16px 0px #30384B1A", height: "80%", padding: "20px"}}>
                <FlexBox justify={"space-between"}>
                    <h3 style={{color: "#364454", fontWeight: "500", fontSize: "20px"}}>Tests Taken by <span
                        style={{fontWeight: "bold"}}>{candidate.firstName}</span></h3>
                </FlexBox>
                <br/>
                <QuizzesTable setIframeSrc={setIframeSrc} setShowIframe={setShowIframe} setQuizzes={setQuizzes} quizzes={quizzes.filter && quizzes.filter(e=>e.quizScore != null) || []} candidate={candidate} label={"taken by"} />

                {sendTest && <SendTest candidate={candidate} setShow={setSendTest} refresh={refresh} />}
            </div>
        </>
    )
}

const QuizzesTable = ({setQuizzes ,quizzes, candidate, label, setIframeSrc, setShowIframe}) => {
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [selectedQuiz, setSelectedQuiz] = useState()
    const company = useContext(CompanyTeamContext) || {}
    let refreshQuota = company.refresh
    const [privileges, setPrivileges] = useState([])
    const navigate = useNavigate()

    useEffect(()=>{
        if(company && company.privileges){
            setPrivileges(company.privileges || []);
        }
    },[company])



    const deleteQuiz = () => {
        if(!selectedQuiz) return;
        const id = CustomLoadingToast("Deleting...")
        axios.delete(API_URL_PREFIX+"/DeleteQuizOfCandidate.html?quizId=" + selectedQuiz.key)
            .then(e => {
                let data = e.data;
                if (data && !data.err) {
                    setQuizzes(quizzes => quizzes.filter(e => e.key != selectedQuiz.key))
                    if(refreshQuota) refreshQuota();
                    updateCustomSuccessToast("Deleted", id)
                } else {
                    updateCustomErrorToast(data.info, id)
                }
                setShowConfirmDelete(false)
            })
            .catch(err => {
                setShowConfirmDelete(false)
                updateCustomErrorToast("Error, Please try again", id)
                console.log(err)
            })
    }

    if(!quizzes || !quizzes.length){
        return <div style={{maxHeight:"280px", overflow:"auto"}}>
            <Table>
            <TableHead columns={["", "", "", "", "","",""]}/>
            <TableBody>
                <tr>
                    <td></td>
                    <td style={{textAlign:"center", width:"100%", color:"#bababa"}}>No quizzes were {label} {candidate.firstName}</td>
                </tr>
            </TableBody>
        </Table>
        </div>;

    }



    return <div style={{maxHeight:"280px", overflow:"auto"}}><Table>

        <TableHead columns={["", "Test Name", "Date",
            <FlexBox justify={"start"} style={{height:"100%"}}>Score&nbsp;<Tooltip text={"Score out of 100"} /></FlexBox>
            ,<FlexBox justify={"start"} style={{height:"100%"}}>Status&nbsp;<Tooltip text={"Pass score set at 60%"} /></FlexBox>,"Supervised","",""]}/>
        <TableBody>
            {quizzes && quizzes.length > 0 && quizzes.map((q, i) => {
                if(q.quizScore == null){
                    return (<TableRow>
                        <td style={{color:"#bababa"}}>{+i + 1}</td>
                        <td>{q.predefinedTest ? <span style={{fontWeight:"bold"}}>{getTestName(q)}</span> : <Link className={"candidate-link"} to={APP_URL_PREFIX+"/test/"+q.quizTemplateKey}>{getTestName(q)}</Link>}</td>
                        <td>{new Date(q.date).toDateString()}</td>
                        <td></td>
                        <td>{q.isExpired ? <Status label={"EXPIRED"} status={"expired"}/> :<Status status={"pending"}/>}</td>
                        <td style={{textAlign:"right", marginRight:"10px"}}>
                            <Button style={{marginRight:"15px"}} hidden={q.isExpired || q.quizScore || q.quizScore === 0} onClick={() => {
                                resendTest(q.key, candidate.key)
                            }} variant={"outline-primary"}> Resend Test</Button>
                        </td>
                        <td style={{textAlign:"right", marginRight:"10px"}}>

                            <Button hidden={q.quizScore || q.quizScore === 0} onClick={() => {
                                setSelectedQuiz(q);
                                setShowConfirmDelete(true)
                            }} variant={"outline-danger"}><X/> Delete</Button>
                        </td>
                    </TableRow>)
                }
                else{
                    let score = q.quizScore;
                    let status=""
                    score = (score ? Math.floor(score) + "" : score);
                    if(q.quizScore == -1){
                        // TEST DISQUALIFIED - PRINT SCREEN
                        if("1"==(q.disqualificationStatus)){
                            score = <><span style={{color:"red"}}>Disqualified</span> <Tooltip text={"Failure to stay on the test tab."} /></>
                        }
                        // TEST DISQUALIFIED - NO GOOGLE
                        else if("2"==(q.disqualificationStatus)){
                            score = <><span style={{color:"red"}}>Disqualified</span>  <Tooltip text={"Print screen action detected during the test."} /></>
                        }
                        // TEST DISQUALIFIED - Screen Share Cancel
                        else if("3"==(q.disqualificationStatus)){
                            score = <><span style={{color:"red"}}>Disqualified</span>  <Tooltip text={"Screen sharing was stopped during the test."} /></>
                        }
                     // TEST DISQUALIFIED - ChatGPT
                        else if("4"==(q.disqualificationStatus) || "5"==(q.disqualificationStatus)){
                            score = <><span style={{color:"red"}}>Disqualified</span>  <Tooltip text={"Unauthorized use of AI tools detected."} /></>
                        }
                        // TEST STOPPED IN THE MIDDLE
                        else{
                            score = <>Stopped <Tooltip text={"Test Stopped: Did not reach completion."} /></>
                        }
                    }
                    else{
                        status = <Status status={"pending"} />;
                        if(q.quizScore || q.quizScore == 0){
                            if((isLiveTestOrQuiz(q) && q.liveTestResult !== 'passed' && q.liveTestResult !== 'failed')) {
                                status = 'To be reviewed'
                            }
                            else if(q.quizScore > PASS_THRESHOLD || (isLiveTestOrQuiz(q) && q.liveTestResult === 'passed')){
                                status = <Status status={"passed"} />
                            }
                            else{
                                status = <Status status={"failed"} />
                            }
                        }
                    }
                    return (<TableRow>
                        <td style={{color:"#bababa"}}>{+i + 1}</td>
                        <td>{q.predefinedTest ? <span style={{fontWeight:"bold"}}>{getTestName(q)}</span> : <Link className={"candidate-link"} to={APP_URL_PREFIX+"/test/"+q.quizTemplateKey}>{getTestName(q)}</Link>}</td>
                        <td>{new Date(q.date).toDateString()}</td>
                        <td style={{fontWeight:"bold"}}>{isLiveTestOrQuiz(q) ? '' : score}</td>
                        <td>{status}</td>
                        <td>
                            <FlexBox style={{width:"100%"}} justify={"space-between"} align={"center"}>
                                {q.useWebCam && <CameraFill style={{cursor:"pointer"}} onClick={e=>{
                                    setShowIframe(true)
                                    setIframeSrc(`https://www.questions-bank.com/ShowProfileImages.html?liteColor=1&quizId=${encodeURIComponent(q.key)}&candName=${encodeURIComponent((q.candidateName))}`)
                                }}

                                                                   title={"View Profile Pictures"} size={"25px"} color={"#125AB3"} />}
                                {(q.didRiskIdentified || q.disqualificationStatus > 3) && <Images onClick={e=>{
                                    setShowIframe(true)
                                    setIframeSrc(`https://lugotest-hrd.appspot.com/PrintScreenReport.htm?liteColor=1&testKey=${encodeURIComponent(q.key)}&testName=${encodeURIComponent(q.name)}&applicantName=${encodeURIComponent(q.candidateName)}`)
                                }} style={{cursor:"pointer"}} size={"24px"} title={"View Screenshots"} color={"#125AB3"} />}
                            </FlexBox>

                        </td>
                        <td>
                            <FlexBox style={{cursor: "pointer", position: "relative"}} justify={"end"} align={"center"}>
                                {privileges.includes(TEST_REVIEW_PRIVILEGE)
                                    ?<>
                                        <TestReviewButton quiz={q} navigate={navigate}/>
                                    </>
                                    :
                                    ""}
                            </FlexBox>
                        </td>
                        <td>
                            <FlexBox style={{cursor: "pointer", position: "relative"}} justify={"end"} align={"center"}>
                                <TestChartsButton quiz={q} navigate={navigate}/>
                            </FlexBox>
                        </td>
                         <td style={{textAlign:"right", marginRight:"10px"}}>
                            <Button style={{marginRight:"15px"}} hidden={(!q.quizScore && q.quizScore !== 0) || q.quizScore == -1} onClick={() => {
                                resendResults(q.key, candidate.key)
                            }} variant={"outline-primary"}> Resend Results</Button>
                        </td>
                        

                    </TableRow>)
                }

            })}
        </TableBody>
        {showConfirmDelete
            && <ConfirmModal variant={"danger"} show={showConfirmDelete} setShow={setShowConfirmDelete}
                             callback={deleteQuiz} body={"Are you sure you want to delete " + getCandidateFullName(candidate)+ "'s "+selectedQuiz.name+" quiz?"}
                             title={"Confirm Delete"} yes={"Delete"} no={"Cancel"}
                             fallback={e => setShowConfirmDelete(false)}/>}
    </Table></div>;
}



export default Candidate



