import './Dashboard.css'
import { PieChart,pieArcLabelClasses } from '@mui/x-charts';
import { Box, Typography } from '@mui/material';
import Table from "../../components/utilities/table/Table";
import TableHead from "../../components/utilities/table/TableHead";
import TableBody from "../../components/utilities/table/TableBody";
import TransparentPageLayout from "../../layout/page-layout/TransparentPageLayout";
import FlexBox from "../../components/utilities/FlexBox";
import {Button} from "react-bootstrap";

import Noplans from "../../assets/dashboard/noplans.png";
import Loader from "../../components/utilities/Loader";
import {useContext, useEffect, useState} from "react";
import TableRow from "../../components/utilities/table/TableRow";
import axios from "axios";
import {CustomErrorToast, CustomToastContainer} from "../../components/utilities/CustomToast";
import {getCandidateFullName, getUrlParameter} from "../../utilities/Utilities";
import {API_URL_PREFIX, APP_URL_PREFIX} from "../../utilities/Constants";
import CompanyTeamContext from "../../CompanyTeamContext";
import {Link, useNavigate} from "react-router-dom";
import Tooltip from "../../components/utilities/Tooltip";
import {getTestName, isLiveTestOrQuiz} from "../test/tests-list/TestsList";


const Dashboard = () => {
    const company = useContext(CompanyTeamContext) || {};
    const navigate = useNavigate()
    const navigation = useNavigate();

    useEffect(()=>{
        if (getUrlParameter('url')) {
            let url = getUrlParameter('url');

            if(url.includes("?")){
                let parts = url.split("?")
                const goToPage = () => {
                    navigate({
                        pathname: parts[0],
                        search: parts[1],
                    });
                }
                goToPage();
            }
            else{
                navigation(getUrlParameter('url'))
            }

        }

        if(company){
            document.title  = "Dashboard - " + (company.basicTitle ? company.basicTitle : "Lugo-Test")
            return () => {
                document.title = company.basicTitle
            }
        }
    },[company])
    

    if(!company || !company.selectedLitePlan){
        return (
            <TransparentPageLayout height={"100%"}>
                <FlexBox justify={"start"} direction={"column"} style={{width:"100%", height:"700px"}}>
                    <p style={{fontFamily:"", fontSize:"55px",backgroundColor: "",fontWeight:"bold",textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                        color: "#000"}}>Choose a Plan to Start</p>
                    <img style={{maxHeight:"80%", width:"auto"}} src={Noplans} />
                    <Button size={"lg"} variant={"primary"} onClick={e=>navigate(APP_URL_PREFIX +"/billing")}>All Plans</Button>
                </FlexBox>
            </TransparentPageLayout>
        )
    }





    return (<TransparentPageLayout>
        <CustomToastContainer/>
        <FlexBox style={{height: "100%", width: "100%"}} align={"start"}>

            <FlexBox style={{width: "40%", marginRight: "25px", maxHeight: "100%"}} direction={"column"}>
            <CandidateStatusPieChart />
                <br/>
                <RecentExpiredTests/>
                {/*<TestsQuota />*/}

            </FlexBox>


            <FlexBox style={{width: "60%", marginRight: "25px", maxHeight: "100%"}} direction={"column"}>
                <RecentResults/>
                <br/>
                <RecentPendingQuizzes/>
            </FlexBox>


        </FlexBox>
    </TransparentPageLayout>)

}
export default Dashboard



  const CandidateStatusPieChart = () => {

    const [chartData, setChartData] = useState([]);    

    // Fetch data from the API
    useEffect(() => {
      const fetchChartData = async () => {
        try {
          const response = await axios.get(API_URL_PREFIX+'/GetLast30DaysStats.html');
          const [completed, pending, expired] = response.data;

          const data = [];

          if (parseInt(completed, 10) > 0) {
            data.push({ id: 0, label: 'Completed', value: parseInt(completed, 10), color: '#774cf3'});
          }
          if (parseInt(pending, 10) > 0) {
            data.push({ id: 1, label: 'Pending', value: parseInt(pending, 10), color: '#b9aafd' });
          }
          if (parseInt(expired, 10) > 0) {
            data.push({ id: 2, label: 'Expired', value: parseInt(expired, 10), color: '#979DA5'});
          }

          // Update state with the new chart data
          setChartData(data);
            } catch (error) {
          console.error('Error fetching chart data:', error);
        }
      };

      fetchChartData();
    }, []);


    return (
      <Box
        sx={{
          backgroundColor: '#fff',
          padding: 2,
          borderRadius: 1,
          boxShadow: 1,
          width: '100%',
          maxWidth: 600,
          margin: '0 auto',
        }}
      >
        <Typography
          variant="h6"
          component="h3"
          sx={{ marginBottom: 2, fontWeight: '700',color: '#364454',  fontSize: '20px' ,fontFamily: 'Nunito Sans'}}          
        >
          Test Status - Last 30 Days
        </Typography>
        

        <PieChart  
         
          series={[
            {
              data: chartData,
              innerRadius: 40,
              outerRadius: 136,
              paddingAngle: 2,
              cx: 180,
              cy: 145,
              arcLabel: (item) => `${item.value}`              
            },
          ]}
          width={600}
          height={300}
          slotProps={{
            legend: {
              direction: 'column',
              position: { vertical: 'top', horizontal: 'right' },
              padding: 100,
            },
          }}
        sx={{
            [`& .${pieArcLabelClasses.root}`]: (item) => ({
        	fill: "#ffffff",
              fontWeight: 'normal',
              fontFamily: 'Nunito Sans',
              fontSize: '22px',
            }),
          }}
        />
      </Box>
    );
  };  

function RecentExpiredTests({label = "Recently Expired Tests", url = API_URL_PREFIX+"/GetRecentExpired.html", idName}) {
    const [quizzes, setQuizzes] = useState([])
    const [loaded, setLoaded] = useState(false)
    const company = useContext(CompanyTeamContext)

    useEffect(() => {
        if (!company) return;
        if (quizzes && quizzes.length > 0) return;
        axios.get(url, {"Content-Type": "application/json"})
            .then(e => {
                setLoaded(true)
                let data = e.data;
                if (!data || data.err) {
                    CustomErrorToast(data.info)
                } else {
                    setQuizzes(data)
                }
            })
            .catch(err => {
                setLoaded(true)
                CustomErrorToast("Error loading " + label.toLowerCase())
                console.log(err)
            })
    }, [company])


    return (<div className={"container soft-shadow"}
                 style={{
                     width: "100%", padding: "20px", background: "#fff",
                 }}>
        <FlexBox style={{marginBottom: "10px"}} justify={"space-between"} align={"start"} direction={"column"}>
            <FlexBox justify={"space-between"} style={{
                lineHeight: "20px", color: "#364454", fontWeight: "700", fontSize: "20px", width: "100%"
            }}>
                <div style={{width: "70%"}}>{label}</div>
                <Link style={{
                    lineHeight: "0",
                    fontSize: "13px",
                    textAlign: "right",
                    width: "30%",
                    textDecoration: "none"
                }} to={APP_URL_PREFIX + "/quiz/list/expired"}>View All</Link>
            </FlexBox>
            <br/>
            <CustomToastContainer/>
            <div style={{width: "100%",height: "287px", overflow: "auto"}}>
                <Table id={idName}
                    style={{width: "100%", maxHeight: "400px", minHeight: "80px", overflow: "auto", marginBottom: "0"}}>
                    <TableHead columns={["", "Name", "Test name"]}></TableHead>
                    <TableBody style={{textAlign: "center"}}>
                        {(loaded && quizzes) ? (quizzes.length > 0 ? quizzes.map(q => <TableRow
                                style={{textAlign: "left", color: "#636E7B", height: "40px"}}>
                                <td style={{width: "30px"}}></td>
                                <td  ><Link className={"candidate-link"} 
                                to={APP_URL_PREFIX + "/candidate/" + q.candidateKey}>{q.candidateName}</Link></td>
                                <td>{q.predefinedTest ? <span >{getTestName(q, 32)}</span> :
                                    <Link className={"candidate-link"} to={APP_URL_PREFIX + "/test/" + q.testKey}>{getTestName(q, 32)}</Link>}</td>
                            </TableRow>) :
                            <td style={{verticalAlign: "middle", color: "#bababa"}} rowSpan={4} colSpan={3}>No Recent
                                Expired Tests</td>) : <Loader loader={!loaded}/>}
                    </TableBody>
                </Table>
            </div>
        </FlexBox>

    </div>);
}

function RecentResults() {

    const [quizzes, setQuizzes] = useState([])
    const [loaded, setLoaded] = useState(false)
    const company = useContext(CompanyTeamContext)

    useEffect(() => {
        if (quizzes && quizzes.length > 0) return;
        if (!company) return;
        axios.get(API_URL_PREFIX+"/GetRecentResults.html")
            .then(e => {
                setLoaded(true)
                let data = e.data;
                if (!data || data.err) {
                    CustomErrorToast(data.info)
                } else {
                    setQuizzes(data.sort((a,b)=> {
                        return b.quizDate - a.quizDate;
                    }))
                }
            })
            .catch(err => {
                setLoaded(true)
                CustomErrorToast("Error loading recent results.")
                console.log(err)
            })
    }, [company])


    return (<div className={"container soft-shadow"}
                 style={{
                     width: "100%", maxHeight: "50%", overflow: "auto", padding: "20px", background: "#fff",
                 }}>
        <FlexBox style={{marginBottom: "10px"}} justify={"space-between"} align={"start"} direction={"column"}>
            <FlexBox justify={"space-between"} style={{
                lineHeight: "20px", color: "#364454", fontWeight: "700", fontSize: "20px", width: "100%"
            }}>
                <div style={{width: "100%"}}>Recently Completed Tests</div>
                <Link style={{
                    lineHeight: "0",
                    fontSize: "13px",
                    textAlign: "right",
                    width: "100%",
                    textDecoration: "none"
                }} to={APP_URL_PREFIX + "/quiz/list/completed"}>View All</Link>
            </FlexBox>
            <br/>
            <div style={{width: "100%",height:"287px", overflow: "auto"}}>
                <Table
                    style={{width: "100%", maxHeight: "231px", minHeight: "80px", overflow: "auto", marginBottom: "0"}}>
                    <TableHead columns={["", "Candidate Name", "Test name", <FlexBox justify={"start"} style={{height:"100%"}}>Score&nbsp;<Tooltip text={"Score out of 100"} /></FlexBox>, "Date"]}></TableHead>
                    <TableBody style={{textAlign: "center"}}>
                        {loaded ? (quizzes.length > 0 ? quizzes.map(q => {
                            let score = q.quizScore;
                            score = (score ? Math.floor(score): score)+"%";
                            if (q.quizScore == -1) {
                                // TEST DISQUALIFIED - PRINT SCREEN
                                if ("1" == (q.disqualificationStatus)) {
                                    score = <><span style={{color:"red"}}>Disqualified</span> <Tooltip text={"Failure to stay on the test tab."} /></>
                                }
                                // TEST DISQUALIFIED - NO GOOGLE
                                else if ("2" == (q.disqualificationStatus)) {
                                    score = <><span style={{color:"red"}}>Disqualified</span>  <Tooltip text={"Print screen action detected during the test"} /></>
                                }
                                // TEST DISQUALIFIED - Screen Share Cancel
                                else if("3"==(q.disqualificationStatus)){
                                    score = <><span style={{color:"red"}}>Disqualified</span>  <Tooltip text={"Screen sharing was stopped during the test"} /></>
                                }
                             // TEST DISQUALIFIED - ChatGPT
                                else if("4"==(q.disqualificationStatus) || "5"==(q.disqualificationStatus) ){
                                    score = <><span style={{color:"red"}}>Disqualified</span>  <Tooltip text={"Unauthorized use of AI tools detected"} /></>
                                }
                                else if("6"==(q.disqualificationStatus)){
                                    score = <><span style={{color:"red"}}>Disqualified</span>  <Tooltip text={"Unauthorized use of Developer tools detected."} /></>
                                }

                                // TEST STOPPED IN THE MIDDLE
                                else {
                                    score = <>Stopped <Tooltip text={"Test Stopped: Did not reach completion."} /></>
                                }
                            }


                            return <TableRow style={{textAlign: "left", color: "#636E7B", height: "40px"}}>
                                <td style={{width: "30px"}}></td>
                                <td  ><Link className={"candidate-link"} 
                                    to={APP_URL_PREFIX + "/candidate/" + q.candidateKey}>{q.candidateName}</Link></td>
                                <td>{q.predefinedTest ? <span >{getTestName(q, 40)}</span> :
                                    <Link className={"candidate-link"} to={APP_URL_PREFIX + "/test/" + q.testKey}>{getTestName(q, 40)}</Link>}</td>
                                <td>{isLiveTestOrQuiz(q) ? '': score}</td>
                                <td>{new Date(q.quizDate).toLocaleDateString()}</td>
                            </TableRow>
                        }) : <td style={{verticalAlign: "middle", color: "#bababa"}} rowSpan={4} colSpan={4}>No Recent
                            Completed Tests</td>) : <Loader loader={!loaded}/>}
                    </TableBody>
                </Table>
            </div>
        </FlexBox>

    </div>);
}

function RecentPendingQuizzes() {

    const [quizzes, setQuizzes] = useState([])
    const [loaded, setLoaded] = useState(false)
    const company = useContext(CompanyTeamContext)

    useEffect(() => {
        if (quizzes && quizzes.length > 0) return;
        if (!company) return;
        axios.get(API_URL_PREFIX+"/GetRecentPendingQuizzes.html")
            .then(e => {
                setLoaded(true)
                let data = e.data;
                if (!data || data.err) {
                    CustomErrorToast(data.info)
                } else {
                    setQuizzes(data.sort((a,b)=> {
                        return b.quizDate - a.quizDate;
                    }))
                }
            })
            .catch(err => {
                setLoaded(true)
                CustomErrorToast("Error loading recent pending tests.")
                console.log(err)
            })
    }, [company])

    return (<div className={"container soft-shadow"}
                 style={{
                     width: "100%", maxHeight: "50%", overflow: "auto", padding: "20px", background: "#fff",
                 }}>
        <FlexBox style={{marginBottom: "10px", width: "100%"}} justify={"space-between"} align={"start"}
                 direction={"column"}>
            <FlexBox justify={"space-between"} style={{
                lineHeight: "20px", color: "#364454", fontWeight: "700", fontSize: "20px", width: "100%"
            }}>
                <div style={{width: "100%"}}>Recently Pending Tests</div>
                <Link style={{
                    lineHeight: "0",
                    fontSize: "13px",
                    textAlign: "right",
                    width: "100%",
                    textDecoration: "none"
                }} to={APP_URL_PREFIX + "/quiz/list/pending"}>View All</Link>
            </FlexBox>
            <br/>            
            <div style={{width: "100%",height:"287px", overflow: "auto", textAlign: "center"}}>
                <Table
                    style={{width: "100%", maxHeight: "235px", minHeight: "80px", overflow: "auto", marginBottom: "0"}}>
                    <TableHead columns={["", "Candidate Name", "Test name", "Sent at"]}></TableHead>
                    <TableBody style={{textAlign: "center"}}>
                        {loaded ? (quizzes.length > 0 ? quizzes.map(q => <TableRow
                                style={{textAlign: "left", color: "#636E7B", height: "40px"}}>
                                <td style={{width: "30px"}}></td>
                                <td  ><Link className={"candidate-link"} 
                                    to={APP_URL_PREFIX + "/candidate/" + q.candidateKey}>{q.candidateName}</Link></td>
                                <td>{q.predefinedTest ? <span >{getTestName(q, 40)}</span> :
                                    <Link className={"candidate-link"} to={APP_URL_PREFIX + "/test/" + q.testKey}>{getTestName(q, 40)}</Link>}</td>
                                <td>{new Date(q.quizDate).toLocaleDateString()}</td>
                            </TableRow>) :
                            <td style={{verticalAlign: "middle", color: "#bababa"}} rowSpan={4} colSpan={4}>No Recent
                                Assigned Tests</td>) : <Loader loader={!loaded}/>}
                    </TableBody>
                </Table>

            </div>
        </FlexBox>

    </div>);
}