import RequiredNote from "./RequiredNote";

const InputText = ({ref, onClick=()=>{}, idName, marginRight="30px",
                       divWidth="", setField, field,height="30px",
                       label, mode="black", type="text", className="",
                       style={}, required=false, disabled=false,
                       width="100%", placeHolder="",
                   onKeyDown=()=>{}, onFocus=()=>{}, onBlur=()=>{}, hidden = false})=>{
    if(hidden) return "";
    if(idName){
        return (
            <div style={{marginRight:marginRight,width:divWidth}}>
                {label && <label style={{fontSize: "14px", color:mode}} className="form-label">{label}&nbsp;{required && <RequiredNote/>}</label>}
                <input onBlur={onBlur} onKeyDown={onKeyDown} onFocus={onFocus} placeholder={placeHolder} onClick={onClick} id={idName} disabled={disabled} type={type} style={{...style,height:height,borderRadius:"2px",fontSize: "14px",width:width}} onChange={e => setField(e)} value={field} className={"form-control input " +className}
                />
            </div>
        )
    }

    return (
        <div style={{marginRight:marginRight,width:divWidth}}>
            {label && <label style={{fontSize: "14px", color:mode}} className="form-label">{label}&nbsp;{required && <RequiredNote/>}</label>}
            <input onBlur={onBlur} onKeyDown={onKeyDown} onFocus={onFocus} placeholder={placeHolder} disabled={disabled} type={type} style={{...style,height:height,borderRadius:"2px",fontSize: "14px",width:width}} onChange={e => setField(e)} value={field} className={"form-control input " +className}
                  />
        </div>
    )
}

export const TopInputLabel = ({marginRight="30px",divWidth="", label, mode="black", required=false, children, className})=>{
    return (
        <div style={{marginRight:marginRight,width:divWidth}}>
            <label className={className} style={{fontSize: "14px", color:mode}} className="form-label">{label}&nbsp;{required && <RequiredNote/>}</label>
            {children}
        </div>
    )
}

export const InputSelect = ({marginRight="30px",children,setField, field, label, mode="black", type="text", className="", style={}, required=false, disabled=false})=>{
    return (
        <div style={{marginRight:marginRight}}>
            {label && <label style={{fontSize: "14px", color:mode}} className="form-label">{label}&nbsp;{required && <RequiredNote/>}</label>}
            <select disabled={disabled} style={{...style,borderRadius:"2px",fontSize: "14px",width: "100%"}} onChange={e => setField(e)} value={field} className={"input " +className}>
                {children}
            </select>
        </div>
    )
}

export default InputText