import {Route, Routes} from "react-router-dom";
import {lazy, Suspense} from "react";
import Loader from "../components/utilities/Loader";

// Lazy load the imports to save time
import CandidatesList  from "../pages/candidate/cadidates-list/CandidatesList"
import NewCandidate  from "../pages/candidate/candidate-new/NewCandidate"
import TestsList  from "../pages/test/tests-list/TestsList"
import TestBuilder  from "../pages/test/test-new-view/TestBuilder"
import Candidate  from "../pages/candidate/candidate-id/Candidate"
import Billing  from "../pages/billing/Billing"
import Dashboard  from "../pages/dashboard/Dashboard"
import ErrorBoundary  from "../components/utilities/ErrorBoundery"
import QuizzesList  from "../pages/quiz/quiz/QuizzesList"
import Pricing  from "../pages/billing/Pricing"
import TestReview  from "../pages/iframes/TestReview"
import AdvancedCharts  from "../pages/iframes/AdvancedCharts"
import Invoices  from "../pages/invoices/Invoices"
import FlexBox  from "../components/utilities/FlexBox"
import Preferences  from "../pages/general/Preferences"
import Charts  from "../pages/charts/Charts"
import TestWizard  from "../pages/iframes/TestWizard"
import UsersManagement  from "../pages/user/UsersManagement"
import QuizzesReport  from "../pages/quiz/report/QuizzesReport"
import UserAgreement from "../pages/general/UserAgreement";
import PrivateQuestions from "../pages/iframes/PrivateQuestions";


const Router = () => {
    return (<Suspense fallback={<Loader loader={true}/>}>
        <FlexBox align={"center"} justify={"start"} style={{width: "100%", height: "calc(100% - 72px)"}}>
            <Routes>
                <Route path={"/dashboard"} element={<ErrorBoundary><Dashboard/></ErrorBoundary>}/>
                <Route path={"/"} element={<ErrorBoundary><Dashboard/></ErrorBoundary>}/>
                <Route path={"/index.html"} element={<ErrorBoundary><Dashboard/></ErrorBoundary>}/>
                <Route path={"/candidate/list"} element={<ErrorBoundary><CandidatesList/></ErrorBoundary>}/>
                <Route path={"/candidate/new"} element={<ErrorBoundary><NewCandidate/></ErrorBoundary>}/>
                <Route path={"/candidate/:id/edit"} element={<ErrorBoundary><NewCandidate/></ErrorBoundary>}/>
                <Route path={"/candidate/:id"} element={<ErrorBoundary><Candidate/></ErrorBoundary>}/>
                <Route path={"/test/list"} element={<ErrorBoundary><TestsList/></ErrorBoundary>}/>
                <Route path={"/test/new"} element={<ErrorBoundary><TestBuilder/></ErrorBoundary>}/>
                <Route path={"/test/live/new"} element={<ErrorBoundary><TestBuilder liveTest={true}/></ErrorBoundary>}/>
                <Route path={"/test/new/wizard"} element={<ErrorBoundary><TestWizard/></ErrorBoundary>}/>
                <Route path={"/test/questions/builder"} element={<ErrorBoundary><PrivateQuestions/></ErrorBoundary>}/>
                <Route path={"/test/:id"} element={<ErrorBoundary><TestBuilder/></ErrorBoundary>}/>
                <Route path={"/billing"} element={<ErrorBoundary><Billing/></ErrorBoundary>}/>
                <Route path={"/quiz/list"} element={<ErrorBoundary><QuizzesList/></ErrorBoundary>}/>
                <Route path={"/quiz/report"} element={<ErrorBoundary><QuizzesReport/></ErrorBoundary>}/>
                <Route path={"/quiz/list/:filter"} element={<ErrorBoundary><QuizzesList/></ErrorBoundary>}/>
                <Route path={"/pricing"} element={<ErrorBoundary><Pricing/></ErrorBoundary>}/>
                <Route path={"/invoices"} element={<ErrorBoundary><Invoices/></ErrorBoundary>}/>
                <Route path={"/TestReview/:testId"} element={<ErrorBoundary><TestReview/></ErrorBoundary>}/>
                <Route path={"/AdvancedCharts/:results"} element={<ErrorBoundary><AdvancedCharts/></ErrorBoundary>}/>
                <Route path={"/preferences"} element={<ErrorBoundary><Preferences/></ErrorBoundary>}/>
                <Route path={"/preferences/agreement"} element={<ErrorBoundary><UserAgreement/></ErrorBoundary>}/>
                <Route path={"/charts/:results"} element={<ErrorBoundary><Charts/></ErrorBoundary>}/>
                <Route path={"/user/manage"} element={<ErrorBoundary><UsersManagement/></ErrorBoundary>}/>
                <Route path={"/*"} element={<ErrorBoundary><Dashboard/></ErrorBoundary>}/>
            </Routes>
        </FlexBox>
    </Suspense>)
}

export default Router